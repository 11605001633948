import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
const STRIPE_PUBLISHABLE_KEY = globalThis.__mentimeterEnv['NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY'];
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY!);
  }
  return stripePromise;
};
