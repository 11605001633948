export const FreeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
    <g clipPath="url(#a)">
      <path fill="#196CFF" d="M24 .5H0v24h24V.5Z" />
      <path
        fill="#B4CCE5"
        d="M12 5.6a6.9 6.9 0 1 0 0 13.82 6.9 6.9 0 0 0 0-13.82Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const BasicIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
    <g clipPath="url(#a)">
      <path fill="#FF80AB" d="M24 .5H0v24h24V.5Z" />
      <path
        fill="#FFB8CF"
        d="M5.92 18.57c0-6.68 5.42-12.1 12.1-12.1v12.1H5.91Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const ProIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
    <g clipPath="url(#a)">
      <path fill="#0D6B45" d="M24 .5H0v24h24V.5Z" />
      <path fill="#00DB91" d="M18 6.5H6v12h12v-12Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const EnterpriseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
    <g clipPath="url(#a)">
      <path fill="#6E54BD" d="M24 .5H0v24h24V.5Z" />
      <path fill="#D1D4FF" d="M18 6.48h-4v4.02H9.99v4.01h-4v4H18V6.49Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const ConferenceSmallIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_887_64908)">
      <path d="M24 0.5H0V24.5H24V0.5Z" fill="#FFC638" />
      <path
        d="M12.066 6.50586C12.066 9.85266 9.35276 12.5671 6.00476 12.5671V18.4951C12.6264 18.4951 17.994 13.1275 17.994 6.50586H12.066Z"
        fill="#FFE77A"
      />
    </g>
    <defs>
      <clipPath id="clip0_887_64908">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ConferenceLargeIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_887_60759)">
      <path d="M24 0.5H0V24.5H24V0.5Z" fill="#CB660A" />
      <path
        d="M11.7024 17.0753C15.4644 15.0401 18.0192 11.0585 18.0192 6.48047H13.5408C13.5408 9.37367 11.9172 11.8865 9.53163 13.1573C8.47323 13.7225 7.26363 14.0417 5.98083 14.0417V18.5201C8.05203 18.5201 10.0008 17.9969 11.7024 17.0765V17.0753Z"
        fill="#FFB158"
      />
      <path
        d="M11.2272 6.53125C11.2272 9.42925 8.87761 11.7789 5.97961 11.7789V6.53125H11.2272Z"
        fill="#FFB158"
      />
    </g>
    <defs>
      <clipPath id="clip0_887_60759">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
