import useSWR from 'swr';
import type {
  AxiosErrorToJSON,
  BillingHistoryResponseT,
} from '@mentimeter/http-clients';
import { core, isAxiosError } from '@mentimeter/http-clients';
import { to } from '@mentimeter/__app-split-lib/utils';

export const useBillingHistory = (skip = false) => {
  const {
    data: receipts,
    error,
    isLoading,
    isValidating,
  } = useSWR(!skip ? '/payment-services/billing-history' : null, async () => {
    const [fetchError, response] = await to(
      core().get<BillingHistoryResponseT>('/payment-services/billing-history'),
    );

    if (isAxiosError(fetchError)) {
      fetchError.message =
        'Could not get your receipts at this time, please try again later or contact support if the issue persists.';
      throw fetchError.toJSON();
    }

    return response?.data;
  });

  const hasUnpaidInvoice =
    (receipts && receipts?.length > 0 && receipts[0]?.status === 'open') ??
    false;

  return {
    isLoading,
    isValidating,
    receipts,
    hasUnpaidInvoice,
    error: error as AxiosErrorToJSON,
  };
};
