import React from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';
import { alpha } from '@mentimeter/ragnar-colors';
import { Box } from '../Box';
import type { SkeletonElementT } from './Skeleton.types';
import { getHeight } from './Skeleton.utils';

export const SkeletonElement = ({
  variant,
  height,
  ...rest
}: SkeletonElementT) => {
  const { renderer, theme } = useRagnar();

  const keyframes =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '0%': {
          transform: 'translateX(-200%)',
        },
        '100%': {
          transform: 'translateX(200%)',
        },
      }),
      {},
    );

  return (
    <Box
      width={variant === 'circle' ? '40px' : '100%'}
      height={height ? height : getHeight(theme, variant)}
      bg="neutralWeakest"
      borderRadius={variant === 'circle' ? 'full' : '2xl'}
      overflow="hidden"
      {...rest}
    >
      <Box
        width="100%"
        height="100%"
        style={{
          animationDuration: '1200ms',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
          background: `linear-gradient(90deg, ${alpha(
            theme.colors.bg,
            0.1,
          )}, ${alpha(theme.colors.bg, 0.8)}, ${alpha(theme.colors.bg, 0.1)}`,
          position: 'relative',
          animationName: keyframes,
          content: 'none',
        }}
        {...rest}
      />
    </Box>
  );
};
