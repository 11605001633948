import * as React from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import { styled, COMMON } from '@mentimeter/ragnar-styled';
import { Content } from '@radix-ui/react-popover';
import type { PopoverContentProps as PopoverContentPropsRadix } from '@radix-ui/react-popover';
import type { ComponentProps } from 'react';

const contentShowBottom = () => ({
  '0%': { opacity: 0, transform: `translateY(-10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});
const contentShowTop = () => ({
  '0%': { opacity: 0, transform: `translateY(10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});
const contentShowLeft = () => ({
  '0%': { opacity: 0, transform: `translateX(10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});
const contentShowRight = () => ({
  '0%': { opacity: 0, transform: `translateX(-10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});

const PopoverContentRule: Rule<PopoverContentPropsRadix> = (
  { theme },
  renderer,
) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflow: 'hidden',
  maxWidth: `calc(100vw - ${(theme.space[5] || 0) * 2}px)`,
  maxHeight: `calc(100vh - ${(theme.space[5] || 0) * 2}px)`,
  minWidth: '200px',
  backgroundColor: theme.colors.surface,
  borderRadius: `${theme.radii[2]}px`,
  boxShadow: '0 3px 15px rgba(0,0,0,0.2)',
  zIndex: theme.zIndex.modal,

  '&:focus': {
    outline: 'none',
  },
  '@media screen and (max-width: 600px)': {
    maxWidth: `calc(100vw - ${theme.space[2] * 2}px)`,
    maxHeight: `calc(100vh - ${theme.space[2] * 2}px)`,
  },
  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-side="bottom"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowBottom, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
    '&[data-side="top"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowTop, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
    '&[data-side="left"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowLeft, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
    '&[data-side="right"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowRight, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
  },
});

const PopoverContentRadixStyled = styled(
  Content,
  {
    displayName: 'PopoverContentRadixStyled',
  },
  true,
  [
    'forceMount',
    'side',
    'sideOffset',
    'align',
    'alignOffset',
    'avoidCollisions',
    'collisionBoundary',
    'collisionPadding',
    'arrowPadding',
    'sticky',
    'hideWhenDetached',
  ],
)(
  PopoverContentRule,
  ...COMMON,
  'kosmosBorderRadius',
  'kosmosSpacing',
  'layout',
  'flexbox',
  'position',
);

export type PopoverContentProps = ComponentProps<
  typeof PopoverContentRadixStyled
>;

export const PopoverContent = React.forwardRef(
  ({ ...props }: PopoverContentProps, forwardedRef) => {
    return (
      <PopoverContentRadixStyled
        ref={forwardedRef}
        collisionPadding={16}
        arrowPadding={4}
        sideOffset={1}
        {...props}
      />
    );
  },
);
