import useSWR from 'swr';
import type {
  AxiosErrorToJSON,
  PaymentMethodResponseT,
} from '@mentimeter/http-clients';
import { core, isAxiosError } from '@mentimeter/http-clients';
import { AxiosError } from 'axios';
import type {
  PaymentMethodBankTransferT,
  PaymentMethodCardT,
  PaymentMethodPayPalT,
  PaymentMethodLinkT,
  PaymentMethodT,
} from '.';

const formatPaymentMethod = (
  stripePaymentMethods: Array<PaymentMethodResponseT> | undefined,
): PaymentMethodT | undefined => {
  const method =
    Array.isArray(stripePaymentMethods) && stripePaymentMethods.length > 0
      ? stripePaymentMethods[0]
      : undefined;

  if (method === undefined) return undefined;

  switch (method.type) {
    case 'bank_transfer':
      return {
        type: 'bank_transfer',
      } as PaymentMethodBankTransferT;
    case 'paypal':
      return {
        type: 'paypal',
        email: method.email ?? '',
      } as PaymentMethodPayPalT;
    case 'link':
      return {
        type: 'link',
        email: method.email ?? '',
      } as PaymentMethodLinkT;
    case 'card':
      return {
        type: 'card',
        brand: method.brand,
        expirationMonth: method.exp_month,
        expirationYear: method.exp_year,
        last4: method.last4,
        isExpired: method.expired,
      } as PaymentMethodCardT;
    default:
      return undefined;
  }
};

export function usePaymentMethod(skip = false) {
  const {
    data: paymentMethods,
    error,
    isLoading,
  } = useSWR(!skip ? 'payment-methods' : null, async () => {
    const response = await core().payments.getPaymentMethods();

    return response.data;
  });

  if (skip) {
    return {
      isLoading: false,
      paymentMethod: undefined,
      error: undefined,
    };
  }

  if (error) {
    const errorObject = isAxiosError(error)
      ? error
      : new AxiosError('Unknown error');

    return {
      isLoading: false,
      paymentMethod: undefined,
      error: errorObject.toJSON() as AxiosErrorToJSON,
    };
  }

  return {
    isLoading,
    paymentMethod: formatPaymentMethod(paymentMethods),
    error: undefined,
  };
}
