import type { Rule } from '@mentimeter/ragnar-styled';
import { styled, COMMON } from '@mentimeter/ragnar-styled';
import { Arrow } from '@radix-ui/react-popover';
import type { PopoverArrowProps as PopoverArrowPropsRadix } from '@radix-ui/react-popover';
import type { ComponentProps } from 'react';

const PopoverArrowRule: Rule<PopoverArrowPropsRadix> = ({ theme }) => ({
  fill: theme.colors.surface,
});

const PopoverArrowRadixStyled = styled(
  Arrow,
  {
    displayName: 'PopoverArrowRadixStyled',
  },
  true,
)(PopoverArrowRule, ...COMMON);

export type PopoverArrowProps = ComponentProps<typeof PopoverArrowRadixStyled>;

export const PopoverArrow = PopoverArrowRadixStyled;
