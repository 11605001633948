import { ModalBody, ModalContainer, ModalRoot } from '@mentimeter/ragnar-ui';
import React from 'react';
import { CloseModal } from './CloseModal';

interface CheckoutPopupT {
  children: React.ReactNode;

  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ModalLayout({ onOpenChange, open, children }: CheckoutPopupT) {
  // Our modals (radix) prevent pointer events on the body when open. This makes other modals that might open inside this to not be clickable.
  // In this case, the 3D Secure modal is not clickable.
  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (open) {
      timer = setTimeout(() => {
        document.body.style.pointerEvents = '';
      }, 0);
    }

    return () => clearTimeout(timer);
  }, [open]);

  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <ModalContainer
        size="large"
        width={['100%', '100%', '928px']}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <ModalBody width="100%" height="100%" px={0} py={0}>
          <CloseModal onCloseModal={() => onOpenChange(false)} />
          {children}
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
}
