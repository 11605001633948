import { useSubscriptions } from '@mentimeter/billing';
import {
  CheckoutModal,
  useCheckoutModals,
  type HandlePlanClickT,
} from '@mentimeter/checkout-modal';
import { useRouter } from '@mentimeter/next-navigation';
import { useUser, userPolicies } from '@mentimeter/user';

interface CheckoutModalWrapperPropsT {
  children: ({
    handlePlanClick,
  }: {
    handlePlanClick: HandlePlanClickT;
  }) => React.ReactNode;
}

export function CheckoutModalsWrapper({
  children,
}: CheckoutModalWrapperPropsT) {
  const { push } = useRouter();
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const { isEnterpriseUser } = userPolicies(subscriptions);

  const isCheckoutModalEnabled = !isEnterpriseUser && !user?.isFreeUser;

  const {
    handlePlanClick,
    licensesModalOpen,
    planModalOpen,
    billingCycleModalOpen,
    selectedPlan,
    setLicensesModalOpen,
    setPlanModalOpen,
    setBillingCycleModalOpen,
  } = useCheckoutModals({
    enabled: !user ? false : isCheckoutModalEnabled,
    onDisabledCheckoutModalClick: (href) => {
      push(href ?? '/plans');
    },
  });

  return (
    <>
      {children({ handlePlanClick })}
      <CheckoutModal
        open={planModalOpen}
        onOpenChange={setPlanModalOpen}
        plan={selectedPlan}
        type="plan"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
      <CheckoutModal
        open={licensesModalOpen}
        onOpenChange={setLicensesModalOpen}
        plan={selectedPlan}
        type="licenses"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
      <CheckoutModal
        open={billingCycleModalOpen}
        onOpenChange={setBillingCycleModalOpen}
        plan={selectedPlan}
        type="billing-cycle"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
    </>
  );
}
