import type { PlanCategory } from '@mentimeter/http-clients';

export enum PaymentProcessor {
  OTHER = '',
  UNKNOWN = 'unknown',
  VISA = 'visa',
  MASTERCARD = 'master-card',
  AMERICAN_EXPRESS = 'american-express',
  DISCOVER = 'discover',
  MAESTRO = 'maestro',
  UNION_PAY = 'unionpay',
}

export enum CurrencySymbol {
  EUR = '€',
  GBP = '£',
  USD = '$',
  SEK = 'kr',
}

export enum CurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  SEK = 'SEK',
}

export enum DecimalSeparator {
  COMMA = ',',
  POINT = '.',
}

export enum ThousandSeparator {
  COMMA = ',',
  POINT = '.',
  SPACE = ' ',
}

export enum SymbolPosition {
  LEADING,
  TRAILING,
}

export enum SymbolSeparator {
  SPACE = ' ',
}

export interface PricingFormat {
  decimalSeparator: DecimalSeparator;
  thousandSeparator: ThousandSeparator;
  symbolPosition: SymbolPosition;
  symbolSeparator?: SymbolSeparator;
}

interface MonthlyPlansT {
  [PlanCategory.BASIC_MONTHLY]: number;
  [PlanCategory.EDUCATIONAL_BASIC_MONTHLY]: number;
}

interface ConferencePlansT {
  [PlanCategory.CONFERENCE_SMALL]: number;
  [PlanCategory.CONFERENCE_LARGE]: number;
}

interface YearlyPlansT {
  [PlanCategory.BASIC]: number;
  [PlanCategory.PRO]: number;
  [PlanCategory.EDUCATIONAL_BASIC]: number;
  [PlanCategory.EDUCATIONAL_PRO]: number;
}

export type PlansT = YearlyPlansT &
  Partial<MonthlyPlansT> &
  Partial<ConferencePlansT>;

export interface PlanCacheT {
  name: string;
  category: PlanCategory;
  licenses: number;
  price: string;
  cycle: string;
  billingDate: string;
  discountPercentage?: number;
}

export interface TierT {
  unitAmount: number;
  upTo: number;
}

interface PlanPrice {
  amount: number | null;
  tiers: TierT[] | null;
}

type PlanNames = keyof PlansT;

type PlansPrices = {
  [K in PlanNames]: PlanPrice;
};

export interface LocalPricingT {
  currency: {
    symbol: CurrencySymbol;
    code: CurrencyCode;
  };
  format: PricingFormat;
  otherCharges?: {
    invoiceAdminFee?: number;
  };
  plans: PlansPrices;
}

export interface PaymentMethodCardT {
  type: 'card';
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  last4: string;
  isExpired: boolean;
}

export interface PaymentMethodPayPalT {
  type: 'paypal';
  email: string;
}

export interface PaymentMethodLinkT {
  type: 'link';
  email: string;
}

export interface PaymentMethodBankTransferT {
  type: 'bank_transfer';
}

export type PaymentMethodT =
  | PaymentMethodCardT
  | PaymentMethodPayPalT
  | PaymentMethodBankTransferT
  | PaymentMethodLinkT;

export enum FormattedPlanNames {
  pro = 'Mentimeter Pro',
  basic = 'Mentimeter Basic',
  'basic-monthly' = 'Mentimeter Basic Monthly',
  'educational-pro' = 'Mentimeter Educational Pro',
  'educational-basic' = 'Mentimeter Educational Basic',
  'educational-basic-monthly' = 'Mentimeter Educational Basic Monthly',
  'conference-small' = 'Mentimeter Conference Small',
  'conference-large' = 'Mentimeter Conference Large',
}
