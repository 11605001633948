import type { DSC } from '@mentimeter/ragnar-dsc';
import type { SkeletonElementVariantT } from './Skeleton.types';

export const getHeight = (theme: DSC, variant?: SkeletonElementVariantT) => {
  switch (variant) {
    case 'circle':
      return '40px';
    case 'rectangle':
      return '40px';
    case 'image':
      return '100px';
    default:
      return '40px';
  }
};
