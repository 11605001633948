import { Clickable, ModalDismiss } from '@mentimeter/ragnar-ui';
import React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';

interface CloseModalProps {
  onCloseModal: () => void;
}

export function CloseModal({ onCloseModal }: CloseModalProps) {
  return (
    <ModalDismiss>
      <Clickable
        aria-label="Close modal"
        position="absolute"
        top={0}
        right={0}
        zIndex={1}
        onClick={onCloseModal}
        extend={({ theme }) => ({
          padding: `${theme.space[2] + theme.space[1]}px`,
        })}
      >
        <CrossIcon size={4} />
      </Clickable>
    </ModalDismiss>
  );
}
