import type { PlanCategory } from '@mentimeter/http-clients';
import { PaymentProcessor } from './types';

export const isString = (string: unknown): string is string =>
  typeof string === 'string';

// amount always comes with cents included
export const roundUpAmount = (amount: number) => {
  return Math.ceil(amount / 100);
};

export const calculateDaysUntilSubscriptionEnds = (periodEndDate?: number) => {
  const SECONDS_PER_DAY = 86400;
  return (
    periodEndDate &&
    Math.floor((periodEndDate - new Date().getTime() / 1000) / SECONDS_PER_DAY)
  );
};

export const isChangingBillingCycle = (
  currentPlanCycle: string,
  newPlanCycle: string,
) => {
  return currentPlanCycle === 'month' && newPlanCycle === 'year';
};

export const formatDiscountPercentage = (
  currentMonthlyPrice: number | undefined,
  newYearlyPrice: number | undefined,
  currentPlanCycle: string,
  newPlanCycle: string,
) => {
  if (!currentMonthlyPrice || !newYearlyPrice) return 0;

  if (isChangingBillingCycle(currentPlanCycle, newPlanCycle)) {
    const oldYearly = currentMonthlyPrice * 12;
    const percentage_diff = (oldYearly - newYearlyPrice) * 100;
    return Math.round(percentage_diff / oldYearly);
  } else {
    return 0;
  }
};

export const formatPlanCategory = (category?: PlanCategory) => {
  switch (category) {
    case 'basic':
    case 'basic-monthly':
      return 'Basic';
    case 'educational-basic':
    case 'educational-basic-monthly':
      return 'Educational Basic';
    case 'pro':
      return 'Pro';
    case 'educational-pro':
      return 'Educational Pro';
    case 'pro-trial':
      return 'Pro Trial';
    case 'teams':
      return 'Invoice';
    case 'enterprise':
      return 'Enterprise';
    case 'enterprise-trial':
      return 'Enterprise Trial';
    case 'conference-small':
      return 'Conference Small';
    case 'conference-large':
      return 'Conference Large';
    default:
      return '';
  }
};

export const formatPercentage = (part: number | undefined, total: number) => {
  if (!part) return undefined;
  return Math.round((part / total) * 100) + '%';
};

export const calculateAndFormatAmountPerPeriod = ({
  amount,
  currency,
  subUnit,
  periodToCalculate,
  locale,
}: {
  amount: number | undefined;
  currency: string | undefined;
  subUnit: number | undefined;
  periodToCalculate: 'monthly' | 'yearly';
  locale?: Intl.LocalesArgument;
}): string | undefined => {
  if (amount === undefined || amount === null || !currency || !subUnit) {
    return undefined;
  }

  const perLicenseAmount = amount / subUnit;
  const calculatedAmount =
    periodToCalculate === 'monthly' ? perLicenseAmount / 12 : perLicenseAmount;

  return formatCurrency({ amount: calculatedAmount, currency, locale });
};

export const formatAmount = ({
  amount,
  currency,
  subUnit,
  locale,
}: {
  amount: number | undefined;
  currency: string | undefined;
  subUnit: number | undefined;
  locale?: Intl.LocalesArgument;
}): string | undefined => {
  if (amount === undefined || amount === null || !currency || !subUnit) {
    return undefined;
  }

  return formatCurrency({ amount: amount / subUnit, currency, locale });
};

export const formatCurrency = ({
  amount,
  currency,
  locale,
}: {
  amount: number | undefined;
  currency: string | undefined;
  locale?: Intl.LocalesArgument;
}): string | undefined => {
  return amount?.toLocaleString(locale ?? 'en-US', {
    style: 'currency',
    currency,
  });
};

export function formatDate(date: number | undefined | null, addDays = 0) {
  if (!date) {
    return '';
  }
  const timestamp = new Date(date * 1000);
  timestamp.setDate(timestamp.getDate() + addDays);
  return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(
    timestamp,
  );
}

export function formatPaymentMethodEmail(account?: string) {
  return account?.replace(/\*/g, '•') ?? '';
}

export function mapCreditCardType(creditCardType?: unknown): PaymentProcessor {
  if (!creditCardType || !isString(creditCardType))
    return PaymentProcessor.OTHER;
  const type = creditCardType.toLowerCase();
  switch (type) {
    case 'visa':
      return PaymentProcessor.VISA;
    case 'mastercard':
      return PaymentProcessor.MASTERCARD;
    case 'amex':
    case 'american express':
      return PaymentProcessor.AMERICAN_EXPRESS;
    case 'discover':
      return PaymentProcessor.DISCOVER;
    case 'maestro':
      return PaymentProcessor.MAESTRO;
    case 'unionpay':
      return PaymentProcessor.UNION_PAY;
    case 'unknown':
    default:
      return PaymentProcessor.OTHER;
  }
}
