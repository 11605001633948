import React from 'react';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import { DownloadIcon } from '@mentimeter/ragnar-visuals';
import { useTranslation } from '@mentimeter/i18n';

interface SuccessSummaryProps {
  invoiceUrl: string;
  heading: string;
  text: string;
}

export const PlansSuccessSummary = ({
  invoiceUrl,
  heading,
  text,
}: SuccessSummaryProps) => {
  const { t } = useTranslation('checkoutUiPackage');

  return (
    <Box
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems={['center', 'center', 'flex-start']}
    >
      <Text lineHeight="heading" color="text" fontSize={32} fontWeight="bold">
        {heading}
      </Text>
      <Text mt="space4">{text}</Text>
      <Button variant="primary" href="/app" mt="space8">
        {t('checkout_modal.success_screens.view_presentations')}
      </Button>
      <Button
        variant="subtle"
        iconLeading={DownloadIcon}
        href={invoiceUrl ?? '/app/billing'}
        target="_blank"
        rel="noopener noreferrer"
        mt="space8"
      >
        {t('checkout_modal.success_screens.download_receipt')}
      </Button>
    </Box>
  );
};
