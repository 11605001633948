import * as React from 'react';
import type { BoxT, BadgeRibbonT } from '@mentimeter/ragnar-ui';
import {
  SendIcon,
  UserSuspendIcon,
  UserIcon,
} from '@mentimeter/ragnar-visuals';
import type { KosmosFontSize } from '@mentimeter/ragnar-dsc';
import type { Responsive } from '@mentimeter/ragnar-styled';
import {
  pinkDark100,
  yellowLight500,
  greenLight300,
  purpleLight200,
  gray1300,
  blueLight200,
} from '@mentimeter/ragnar-colors';
import { useRagnar } from '@mentimeter/ragnar-react';
import { useState, type JSX } from 'react';
import { Image } from '../Image';
import { Box } from '../Box';
import { Text } from '../Text';
import { Svg } from '../Svg';
import { Badge, BadgeWrap } from '../Badge';

export type AvatarTypeT =
  | 'user'
  | 'suspended'
  | 'pending'
  | 'non-member'
  | 'invitee';

const AVATAR_COLORS = [
  blueLight200,
  pinkDark100,
  yellowLight500,
  greenLight300,
  purpleLight200,
];

/** Takes a string and hashes it into an integer value. */
function getIntHashFromString(value: string) {
  const randomInt = value
    .split('')
    .reduce(
      (hashCode: number, currentVal: string) =>
        currentVal.charCodeAt(0) +
        (hashCode << 6) +
        (hashCode << 16) -
        hashCode,
      0,
    );

  // Ensure the integer is positive
  return Math.abs(randomInt);
}

export function getAvatarColor(avatarId = '1') {
  const randomInt = getIntHashFromString(avatarId);
  return AVATAR_COLORS[randomInt % AVATAR_COLORS.length]!;
}

export interface AvatarT extends Omit<BoxT, 'size'> {
  itemId?: string;
  initials: string;
  name?: string | null | undefined;
  type?: AvatarTypeT | undefined;
  size?: 'compact' | 'default' | 'large';
  fontSize?: Responsive<KosmosFontSize> | undefined;
  badge?: BadgeRibbonT | undefined;
  profilePictureUrl?: string | null | undefined;
  fillColor?: boolean;
  disabled?: boolean;
}

export const Avatar = (props: AvatarT) => (
  <Box>
    {props.badge ? (
      <BadgeWrap
        compact
        placement={2}
        badge={
          <Badge
            compact
            type={props.badge}
            borderStyle="solid"
            borderColor="bg"
            borderWidth={2}
          />
        }
      >
        <AvatarCircle {...props} />
      </BadgeWrap>
    ) : (
      <AvatarCircle {...props} />
    )}
  </Box>
);

const SIZES = {
  compact: {
    width: '20px',
    height: '20px',
    fontSize: '62.5',
  },
  default: {
    width: '32px',
    height: '32px',
    fontSize: '62.5',
  },
  large: {
    width: '40px',
    height: '40px',
    fontSize: '75',
  },
} as const;

const AvatarCircle = ({
  itemId,
  name,
  initials,
  type,
  bg = getAvatarColor(itemId),
  badge,
  profilePictureUrl,
  size = 'default',
  fontSize,
  width,
  height,
  'aria-label': ariaLabel,
  disabled = false,
  ...rest
}: AvatarT) => {
  const [profilePictureFailedToLoad, setProfilePictureFailedToLoad] =
    useState(false);

  return (
    <>
      {type === 'user' && (
        <Box
          aria-label={ariaLabel}
          width={width || SIZES[size].width}
          height={height || SIZES[size].height}
          borderRadius="full"
          bg={disabled ? 'disabledStrong' : bg}
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          {...rest}
        >
          {profilePictureUrl && !profilePictureFailedToLoad ? (
            <Image
              width="100%"
              src={profilePictureUrl}
              alt={name ? `Profile picture for ${name}` : 'Profile picture'}
              onError={() => {
                setProfilePictureFailedToLoad(true);
              }}
              extend={() => ({
                opacity: disabled ? 0.4 : 1,
              })}
            />
          ) : initials !== '' ? (
            <Text
              color={disabled ? 'onDisabledStrong' : 'inherit'}
              fontSize={fontSize || SIZES[size].fontSize}
              lineHeight="normal"
              extend={() => ({ cursor: 'default' })}
            >
              {initials}
            </Text>
          ) : (
            <UserIcon
              size={1}
              color={disabled ? 'onDisabledStrong' : gray1300}
            />
          )}
        </Box>
      )}

      {(type === 'pending' || type === 'invitee') && (
        <AvatarDottedCircle
          width={width || SIZES[size].width}
          height={height || SIZES[size].height}
          icon={<SendIcon size={1} color="textWeak" />}
          badge={badge}
          {...rest}
        />
      )}
      {type === 'suspended' && (
        <AvatarDottedCircle
          width={width || SIZES[size].width}
          height={height || SIZES[size].height}
          disabled={true}
          icon={<UserSuspendIcon size={2} color="borderStrong" />}
          badge={badge}
          {...rest}
        />
      )}
      {type === 'non-member' && (
        <AvatarDottedCircle
          width={width || SIZES[size].width}
          height={height || SIZES[size].height}
          icon={<UserIcon size={1} color="textWeak" />}
          badge={badge}
          {...rest}
        />
      )}
    </>
  );
};

interface AvatarDottedCircleT extends BoxT {
  icon: JSX.Element;
  badge: BadgeRibbonT | undefined;
  disabled?: boolean;
  fillColor?: boolean;
}

const AvatarDottedCircle = ({
  icon,
  badge,
  width,
  height,
  disabled = false,
  bg,
  fillColor,
  ...rest
}: AvatarDottedCircleT) => {
  const { theme } = useRagnar();
  return (
    <Box
      width={width}
      height={height}
      position="relative"
      alignItems="center"
      justifyContent="center"
      {...rest}
      top={badge && '5px'}
    >
      <Box
        position="absolute"
        left="50%"
        top="50%"
        width="100%"
        height="100%"
        extend={() => ({
          transform: 'translate(-50%, -50%)',
        })}
      >
        <Svg viewBox="0 0 100 100">
          <circle
            cx="50"
            cy="50"
            r="48"
            fill={fillColor ? theme.colors.bg : 'transparent'}
            stroke={
              disabled ? theme.colors.borderStrong : theme.colors.textWeak
            }
            strokeWidth="3"
            strokeDasharray="12, 11"
          />
        </Svg>
      </Box>
      <Box
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Box>
    </Box>
  );
};
