import type { ReactNode } from 'react';
import { memo, useState } from 'react';
import { Box, List, Text } from '@mentimeter/ragnar-ui';
import { CheckIcon, ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import * as Select from '@radix-ui/react-select';
import { addUnit } from '@mentimeter/ragnar-utils';

export type ItemRenderFunction = (
  value: string,
  children?: ReactNode,
) => ReactNode;

interface Props {
  'aria-label'?: string;
  disabled?: boolean;
  itemStructure: {
    title?: string;
    values: string[];
  }[];
  selectedValue?: string;
  onValueChange?: (value: string) => void;
  onHighlightedChange?: (value: string) => void;
  onContentMouseLeave?: () => void;
  onOpenChange?: (open: boolean) => void;
  getItemIsDisabled?: (value: string) => boolean;
  getDisplayValue?: (value: string) => string;
  renderItem?: ItemRenderFunction;
}

export const Selector = memo(
  ({
    'aria-label': ariaLabel,
    disabled,
    itemStructure,
    selectedValue,
    onValueChange,
    onHighlightedChange,
    onContentMouseLeave,
    onOpenChange,
    getItemIsDisabled,
    getDisplayValue = (value) => value,
    renderItem = (value) => value,
  }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    // We want to track whether highlight has changed, so we can skip calling
    // the onHighlightedValueChange callback on initial opening of the menu.
    const [hasChangedHighlight, setHasChangedHighlight] = useState(false);

    function openChanged(value: boolean) {
      onOpenChange?.(value);
      setIsOpen(value);

      if (!value) setHasChangedHighlight(false);
    }

    if (!itemStructure) return null;

    return (
      <Select.Root
        disabled={Boolean(disabled)}
        open={isOpen}
        onOpenChange={openChanged}
        value={selectedValue || ''}
        onValueChange={onValueChange || (() => {})}
      >
        <Box
          width="100%"
          flexDirection="column"
          alignItems="stretch"
          zIndex={5}
        >
          <Select.Trigger id="slide-type-switcher" type="button" asChild>
            <Box
              as="button"
              aria-label={ariaLabel}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              py="space0.5"
              pr="space2"
              bg="bg"
              borderWidth={1}
              borderRadius="lg"
              borderStyle="solid"
              borderColor="borderStrong"
              data-testid="slide-type-btn"
              extend={({ theme }) => ({
                backgroundColor: `${theme.colors.inputBgActive}`,
                border: `2px solid ${theme.colors.borderWeak}`,
                ':not([data-disabled])': {
                  cursor: 'pointer',
                  ':focus': {
                    outline: `4px solid ${theme.colors.interactiveFocused}`,
                    outlineOffset: '2px',
                    border: `2px solid ${theme.colors.secondary}`,
                  },
                  ':active': {
                    outline: `4px solid ${theme.colors.interactiveFocused}`,
                    outlineOffset: '2px',
                    border: `2px solid ${theme.colors.secondary}`,
                  },
                },
                '[data-disabled]': {
                  opacity: theme.opacity.disabled,
                },
              })}
            >
              <Select.Value
                asChild
                placeholder={
                  <Box
                    height="40px"
                    flexDirection="row"
                    alignItems="center"
                    borderRadius={1}
                    p={2}
                  >
                    <Text color="textWeaker" py={1}>
                      Select licenses
                    </Text>
                  </Box>
                }
              >
                {selectedValue && renderItem(selectedValue)}
              </Select.Value>

              <Select.Icon asChild>
                <Box>
                  <ChevronDownIcon size={3} />
                </Box>
              </Select.Icon>
            </Box>
          </Select.Trigger>

          <Select.Content asChild position="popper">
            <List
              bg="bg"
              maxHeight="40vh"
              borderWidth={1}
              borderStyle="solid"
              borderColor="border"
              zIndex={1}
              pb="space1"
              extend={({ theme }) => ({
                borderRadius: `${addUnit(theme.kosmosBorderRadius.xl)}`,
                width: 'var(--radix-select-trigger-width)',
              })}
              onMouseLeave={onContentMouseLeave}
            >
              <Select.Viewport style={{ width: '100%' }}>
                {itemStructure.map((category, i) => (
                  <Select.Group key={`${category.title}-${i}`}>
                    <Select.Label asChild>
                      <Text
                        color="textWeaker"
                        fontWeight="regular"
                        fontSize={2}
                        lineHeight="125"
                        py="space2"
                        pl="space2"
                        extend={() => ({ display: 'block' })}
                      >
                        {category.title}
                      </Text>
                    </Select.Label>

                    {category.values.map((value) => {
                      return (
                        <Select.Item
                          key={value}
                          id={`item-dropdown-${value}`}
                          data-testid={`item-dropdown-${value}`}
                          value={value}
                          disabled={Boolean(getItemIsDisabled?.(value))}
                          asChild
                        >
                          <Box
                            as="li"
                            flexDirection="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            mx="space4"
                            borderRadius="lg"
                            onFocus={() => {
                              if (hasChangedHighlight)
                                onHighlightedChange?.(value);
                              else setHasChangedHighlight(true);
                            }}
                            extend={({ theme }) => ({
                              cursor: 'pointer',
                              '[data-disabled]': {
                                opacity: 0.4,
                                cursor: 'not-allowed',
                              },
                              '[data-state=checked]': {
                                border: `2px solid ${theme.colors.secondary}`,
                                borderStyle: 'solid',
                                borderWidth: `${theme.kosmosSpacing['space0.5']}`,
                              },
                              '[data-state=unchecked]': {
                                ':focus:not(:hover)': {
                                  outline: `4px solid ${theme.colors.interactiveFocused}`,
                                },
                                ':hover': {
                                  backgroundColor:
                                    theme.colors.secondaryWeakest,
                                },
                              },
                            })}
                          >
                            {renderItem(
                              value,
                              <Select.ItemText>
                                {getDisplayValue(value)}
                              </Select.ItemText>,
                            )}

                            <Select.ItemIndicator asChild>
                              <Box px="space3">
                                <CheckIcon color="onSecondaryWeakest" />
                              </Box>
                            </Select.ItemIndicator>
                          </Box>
                        </Select.Item>
                      );
                    })}
                  </Select.Group>
                ))}
              </Select.Viewport>
            </List>
          </Select.Content>
        </Box>
      </Select.Root>
    );
  },
);
