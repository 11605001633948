import React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import { useRagnar } from '@mentimeter/ragnar-react';
import { Box } from '../Box';
import { Text } from '../Text';
import type { SkeletonTextT } from './Skeleton.types';

export const SkeletonText = ({
  variant = 'text',
  fontSize,
  width = '100%',
  ...rest
}: SkeletonTextT) => {
  const { renderer, theme } = useRagnar();
  const keyframes =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '0%': {
          transform: 'translateX(-200%)',
        },
        '100%': {
          transform: 'translateX(200%)',
        },
      }),
      {},
    );

  return (
    <Box
      as="span"
      overflow="hidden"
      bg="neutralWeakest"
      borderRadius="2xl"
      display="inline-block"
      width={width}
      extend={() => ({
        verticalAlign: 'sub',
      })}
      {...rest}
    >
      <Text
        fontSize={fontSize ? fontSize : variant === 'heading' ? '150' : '100'}
        lineHeight={variant === 'heading' ? 'heading' : 'body'}
        extend={() => ({
          '::before': {
            content: "''",
            display: 'inline-block',
          },
        })}
        style={{
          width: '100%',
          display: 'inline-block',
          animationDuration: '1200ms',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
          background: `linear-gradient(90deg, ${alpha(
            theme.colors.bg,
            0.1,
          )}, ${alpha(theme.colors.bg, 0.8)}, ${alpha(theme.colors.bg, 0.1)}`,
          animationName: keyframes,
        }}
      />
    </Box>
  );
};
