import * as React from 'react';
import { Box } from '../Box/Box';
import type { BadgeWrapT } from './badge.types';

const HEIGHT_NORMAL = 20;
const HEIGHT_COMPACT = 65;

export const BadgeWrap = ({
  children,
  badge,
  compact,
  placement,
  offset = true,
  ...props
}: BadgeWrapT) => (
  <Box {...props} overflow="visible">
    <Box
      position="absolute"
      flexDirection="row"
      zIndex={2}
      top={0}
      right={0}
      extend={() => ({
        transform: offset
          ? placement !== undefined
            ? `translateY(${
                (Number(placement) === 0 && -HEIGHT_NORMAL / 8) ||
                (placement === 1 && -HEIGHT_NORMAL / 4) ||
                (placement === 2 && -HEIGHT_NORMAL / 3) ||
                (placement === 3 && -HEIGHT_NORMAL / 2)
              }px) translateX(${
                (Number(placement) === 0 && HEIGHT_NORMAL / 8) ||
                (placement === 1 && HEIGHT_NORMAL / 4) ||
                (placement === 2 && HEIGHT_NORMAL / 3) ||
                (placement === 3 && HEIGHT_NORMAL / 2)
              }px)`
            : `translateY(${
                compact ? -HEIGHT_COMPACT / 4 : -HEIGHT_NORMAL / 2
              }px) translateX(${
                compact ? HEIGHT_COMPACT / 4 : HEIGHT_NORMAL / 2
              }px)`
          : 'none',
      })}
    >
      {badge}
    </Box>
    {children}
  </Box>
);
