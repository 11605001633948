import React from 'react';
import { SkeletonText } from './SkeletonText';
import { SkeletonElement } from './SkeletonElement';
import type { SkeletonT } from './Skeleton.types';

export const Skeleton = ({
  variant = 'text',
  height,
  fontSize,
  ...rest
}: SkeletonT) => {
  return variant === 'text' || variant === 'heading' ? (
    <SkeletonText variant={variant} fontSize={fontSize} {...rest} />
  ) : (
    <SkeletonElement variant={variant} height={height} {...rest} />
  );
};
