import { ErrorMessage, Skeleton, Button } from '@mentimeter/ragnar-ui';
import {
  formatPaymentMethodEmail,
  mapCreditCardType,
  type PaymentMethodT,
} from '@mentimeter/billing';
import { useTranslation } from '@mentimeter/i18n';
import { useRouter } from '@mentimeter/next-navigation';
import { CreditCardPaymentMethod } from './payment-methods/CreditCardPaymentMethod';
import { PayPalPaymentMethod } from './payment-methods/PayPalPaymentMethod';
import { LinkPaymentMethod } from './payment-methods/LinkPaymentMethod';
import { BankTransferPaymentMethod } from './payment-methods/BankTransferPaymentMethod';
import { NoPaymentMethod } from './payment-methods/NoPaymentMethod';

interface PaymentMethodProps {
  email: string;
  hasError: boolean;
  isLoading: boolean;
  paymentMethod: PaymentMethodT | undefined;
  showChangePlanButton?: boolean;
}

export const PaymentMethod = ({
  paymentMethod,
  email,
  isLoading,
  hasError,
  showChangePlanButton = false,
}: PaymentMethodProps) => {
  const { t } = useTranslation('checkoutUiPackage');

  const router = useRouter();

  const changePlanButton = () => (
    <Button
      variant="subtle"
      size="compact"
      href="/app/change-payment-method"
      onClick={() => router.push('/app/change-payment-method')}
      mt={paymentMethod?.type === 'card' ? 'space0' : 'space4'}
    >
      {t('checkout_modal.payment_method.change')}
    </Button>
  );
  if (isLoading) return <Skeleton mt="space2" width="200px" />;
  if (hasError)
    return (
      <ErrorMessage>{t('checkout_modal.payment_method.error')}</ErrorMessage>
    );

  switch (paymentMethod?.type) {
    case 'card':
      return (
        <CreditCardPaymentMethod
          lastFourDigits={paymentMethod.last4}
          expirationDate={`${paymentMethod.expirationMonth}/${paymentMethod.expirationYear}`}
          type={mapCreditCardType(paymentMethod.brand)}
          isExpired={paymentMethod.isExpired}
          changePlanButton={showChangePlanButton ? changePlanButton : undefined}
        />
      );
    case 'paypal':
      return (
        <>
          <PayPalPaymentMethod
            payPalEmail={formatPaymentMethodEmail(paymentMethod.email)}
          />
          {showChangePlanButton && changePlanButton()}
        </>
      );
    case 'link':
      return (
        <>
          <LinkPaymentMethod
            email={formatPaymentMethodEmail(paymentMethod.email)}
          />
          {showChangePlanButton && changePlanButton()}
        </>
      );
    case 'bank_transfer':
      return <BankTransferPaymentMethod email={email} />;
    default:
      return (
        <>
          <NoPaymentMethod email={email} />
          {showChangePlanButton && changePlanButton()}
        </>
      );
  }
};
