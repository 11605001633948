import React, { useState } from 'react';
import type { PlanT, MetaT } from '@mentimeter/plans-content';
import { AnimatedTextSkeleton } from '@mentimeter/plans-content';
import {
  Box,
  Text,
  Badge,
  TooltipDescriptive,
  Button,
} from '@mentimeter/ragnar-ui';
import { greenLight500 } from '@mentimeter/ragnar-colors';
import { Usps, MetaText } from '@mentimeter/plans-ui';
import type { HandlePlanClickT } from '@mentimeter/checkout-modal';
import { Selector } from '@mentimeter/checkout-ui';
import { getFormattedPrice } from '@mentimeter/billing';
import { useTranslation } from 'react-i18next';
import type { TFunction } from '@mentimeter/i18n';
import { ContactSalesModal } from '@mentimeter/workspace';
import { trackUser } from '@api/tracking/client';
import { H2 } from '../../typography';
import { CheckoutModalsWrapper } from '../CheckoutModalsWrapper';
import { handlePlanCalculationAndFormatting } from '../utils/planCalculations';
import { BuyPlanButton } from './BuyPlanButton';
import { PlanCell } from './PlanCell';

const PLAN_SECTIONS_COUNT = 6;
const PLAN_WIDTH = 320;
const GRID_GAP = 16;
const LICENSE_CAP = 25;

// This is part of the PLS dropdown experiment, if we choose to implement this feature we should remove this
// function that's overriding action children and change the way we set the button text initially / all together
const getButtonText = (plan: PlanT, selectedValue: number, t: TFunction) => {
  const initialBtnText = plan.action.children;

  if (plan.showLicenseSelector && initialBtnText !== t('plans.only_yearly')) {
    const singleLicense = t('plans.licenses_picker.buy_single', {
      plan: plan.heading,
    });
    const multipleLicenses = t('plans.licenses_picker.buy_multiple', {
      licenses: selectedValue,
      plan: plan.heading,
    });
    return selectedValue > 1 ? multipleLicenses : singleLicense;
  }

  return initialBtnText;
};

export function PlansPreviewGrid({ plans }: { plans: PlanT[] }) {
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number>(1);
  const { t } = useTranslation(['plansContentPackage']);

  const numberOfPlans = plans.length;
  const totalGridGap = (numberOfPlans - 1) * GRID_GAP;
  const totalMaxWidth = PLAN_WIDTH * numberOfPlans + totalGridGap;

  return (
    <CheckoutModalsWrapper>
      {({ handlePlanClick }) => (
        <Box
          maxWidth={[PLAN_WIDTH, null, totalMaxWidth]}
          gridTemplateColumns={[null, null, `repeat(${numberOfPlans}, 1fr)`]}
          gridAutoFlow={['row', null, 'column']}
          display="grid"
          gridTemplateRows={`repeat(${PLAN_SECTIONS_COUNT}, max-content)`}
          columnGap={3}
        >
          <ContactSalesModal
            showSalesModal={showSalesModal}
            setShowSalesModal={setShowSalesModal}
            trackingContext="Plans page"
          />
          {plans.map((plan) => {
            const sections = getPlanSections(
              plan,
              selectedValue,
              setSelectedValue,
              t,
              setShowSalesModal,
              handlePlanClick,
            );

            return sections.map((section, index) => {
              const isFirstSection = index === 0;
              const isLastSection = index === sections.length - 1;

              return (
                <PlanCell
                  key={'section-' + section.key}
                  showTopBorder={isFirstSection}
                  showBottomBorder={isLastSection}
                  highlight={plan.highlight ?? false}
                >
                  {section}
                </PlanCell>
              );
            });
          })}
        </Box>
      )}
    </CheckoutModalsWrapper>
  );
}

export const getPlanSections = (
  plan: PlanT,
  selectedValue: number,
  setSelectedValue: (value: number) => void,
  t: TFunction,
  setModalState: (value: boolean) => void,
  onPlanClick?: HandlePlanClickT,
) => {
  const PlanIcon = plan.icon;

  const licenseValues = Array.from({ length: LICENSE_CAP }, (_, i) =>
    (i + 1).toString(),
  );

  const discountPrice =
    (plan?.currencySymbol &&
      plan?.priceFormat &&
      plan.discount?.baseAmount &&
      getFormattedPrice(
        plan.discount.baseAmount,
        plan.currencySymbol,
        plan.priceFormat,
      )) ||
    '';

  const showYearlyDiscount = (item: MetaT) => {
    return (
      !plan.discount &&
      item.yearlyDiscount &&
      item.yearlyDiscountText &&
      !plan.isPriceLoading
    );
  };

  const renderItem = (value: string) => (
    <Box
      height={1}
      flex={1}
      flexDirection="row"
      alignItems="center"
      borderRadius={1}
      p="space2"
    >
      <Text
        ml="space2"
        fontSize={2}
        lineHeight="125"
        fontWeight="regular"
        truncate
      >
        {value}
      </Text>
    </Box>
  );

  return [
    <React.Fragment key={plan.id + '-heading'}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <H2
          mb="space1"
          color="text"
          fontSize={[7, null, 4]}
          fontWeight="semiBold"
        >
          {plan.heading}
        </H2>
        <Box ml="space1" overflow="visible">
          <PlanIcon />
        </Box>
      </Box>
      <Box mt="space2">
        {plan.subheading?.map((item) => (
          <MetaText key={item.text} fontSize={1} meta={item} />
        ))}
      </Box>
    </React.Fragment>,

    <React.Fragment key={plan.id + '-price'}>
      <Box width="100%" justifyContent="end" flex={1}>
        {!!plan.discount && !plan.isPriceLoading && (
          <Box flexDirection="row" alignItems="center">
            <Text
              color="textWeaker"
              extend={() => ({ textDecoration: 'line-through' })}
            >
              {plan.showLicenseSelector
                ? handlePlanCalculationAndFormatting(
                    plan.discount.tiers,
                    plan.discount.baseAmount,
                    selectedValue,
                    plan.priceFormat,
                    plan.currencySymbol,
                    discountPrice,
                    plan.isDisabled,
                  )
                : discountPrice}
            </Text>
            <Badge
              bg={greenLight500}
              px="space2"
              ml="space2"
              type="text"
              color="onPositiveWeakest"
              text={plan.discount.save}
              compact
            />
          </Box>
        )}

        <Box flexDirection="row" width="100%">
          {plan.isPriceLoading ? (
            <AnimatedTextSkeleton />
          ) : (
            <Text
              color="text"
              fontWeight="bold"
              fontSize={7}
              lineHeight="none"
              mt="space1"
              data-testid={`${plan.id}-plan-price`}
            >
              {plan.showLicenseSelector
                ? handlePlanCalculationAndFormatting(
                    plan.tiers,
                    plan.amount,
                    selectedValue,
                    plan.priceFormat,
                    plan.currencySymbol,
                    plan.price,
                    plan.isDisabled,
                  )
                : plan.price}

              {Boolean(plan.pricePostfix) && (
                <Box
                  display="inline-block"
                  extend={() => ({
                    verticalAlign: 'top',
                  })}
                >
                  <Text
                    fontWeight="bold"
                    color="text"
                    extend={() => ({
                      display: 'block',
                    })}
                  >
                    {plan.pricePostfix}
                  </Text>
                </Box>
              )}
            </Text>
          )}
        </Box>
      </Box>
    </React.Fragment>,
    <React.Fragment key={plan.id + '-plan_info'}>
      {plan.newPriceMeta?.map((item) => (
        <Box key={item.text} flexDirection="row" alignItems="center">
          <Text key={item.text} color="textWeaker" fontSize={1}>
            {item.text}
            {showYearlyDiscount(item) && (
              <Box display="inline-block">
                <Badge
                  bg={greenLight500}
                  px="space2"
                  mt={plan.showLicenseSelector ? 'space2' : 0}
                  ml={plan.showLicenseSelector ? 0 : 'space2'}
                  type="text"
                  color="onPositiveWeakest"
                  text={item.yearlyDiscountText}
                  compact
                />
              </Box>
            )}
          </Text>
        </Box>
      ))}
    </React.Fragment>,
    <React.Fragment key={plan.id + '-selector'}>
      {plan.showLicenseSelector && (
        <>
          <Box flexDirection="row" alignContent="center" mb="space2">
            <Text fontSize={1} fontWeight="semiBold" color="text">
              {t('plans.licenses_picker.licenses')}
            </Text>
            <TooltipDescriptive
              id={`${plan.id}-licenses-question-mark`}
              description={t('plans.licenses_picker.tooltip_info')}
            />
          </Box>
          <Selector
            aria-label={t('plans.licenses_picker.licenses_aria_label')}
            disabled={plan?.isDisabled ?? false}
            itemStructure={[{ values: licenseValues }]}
            selectedValue={plan?.isDisabled ? '1' : selectedValue.toString()}
            onOpenChange={(isOpen) => {
              if (isOpen) {
                trackUser({
                  event: 'Clicked license selector',
                  properties: { context: 'Plans page', isOpen },
                });
              }
            }}
            onValueChange={(licenses) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              setSelectedValue && setSelectedValue(parseInt(licenses));
              trackUser({
                event: 'Selected licenses',
                properties: {
                  context: 'Plans page',
                  plan: plan.id,
                  licenses: parseInt(licenses),
                },
              });
            }}
            renderItem={renderItem}
          />
        </>
      )}
    </React.Fragment>,
    <React.Fragment key={plan.id + '-button'}>
      <BuyPlanButton
        highlight={plan.highlight}
        disabled={plan.action.disabled}
        onClick={() => {
          if (selectedValue === LICENSE_CAP) {
            setModalState(true);
          } else {
            onPlanClick?.({
              plan: plan.category,
              isCurrentSubscription: !!plan.isCurrentSubscription,
              isChangingBillingCycle: !!plan.isChangingBillingCycle,
              href: plan.showLicenseSelector
                ? plan.action.href + `?licenses=${selectedValue}`
                : plan.action.href,
            });
          }
          if (plan.track) plan.track();
        }}
      >
        {getButtonText(plan, selectedValue, t)}
      </BuyPlanButton>
      {plan.showQuoteButton && selectedValue > 1 && (
        <Box width="100%" alignItems="center" mt="space4">
          <Button
            variant="secondary"
            target="_blank"
            href={`/quote?licenses=${selectedValue}&plan=${plan.id}`}
            onClick={() => {
              trackUser({
                event: 'Clicked download quote',
                properties: { context: 'Plans page' },
              });
            }}
          >
            {t('plans.download_quote')}
          </Button>
        </Box>
      )}
    </React.Fragment>,
    <React.Fragment key={plan.id + '-usps'}>
      <Text color="text" fontWeight="semiBold" mb="space1">
        {plan.uspHeading}
      </Text>
      <Usps usps={plan.usp} />
    </React.Fragment>,
  ];
};
