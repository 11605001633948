import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';
import { usePlansPageMode } from '../hooks/usePlansPageMode';

interface PlanCellProps {
  children: ReactNode;
  highlight: boolean;
  showTopBorder: boolean;
  showBottomBorder: boolean;
}

export const PlanCell = ({
  children,
  highlight = false,
  showTopBorder = false,
  showBottomBorder = false,
}: PlanCellProps) => {
  const { isCompactMode } = usePlansPageMode();

  return (
    <Box
      px={['space8', 'space8', 'space4', 'space8']}
      pt={showTopBorder ? 'space8' : 'space2'}
      pb={showBottomBorder ? 'space8' : 'space2'}
      mb={showBottomBorder && isCompactMode ? 'space8' : 'space0'}
      bg={highlight ? 'primaryWeakest' : 'bg'}
      borderColor="borderWeak"
      borderStyle="solid"
      borderLeftWidth="1px"
      borderRightWidth="1px"
      borderTopWidth={showTopBorder ? '1px' : 0}
      borderBottomWidth={showBottomBorder ? '1px' : 0}
      extend={({ theme }) => ({
        alignSelf: 'stretch',
        borderTopLeftRadius: showTopBorder ? `${theme.radii[2]}px` : 0,
        borderTopRightRadius: showTopBorder ? `${theme.radii[2]}px` : 0,
        borderBottomLeftRadius: showBottomBorder ? `${theme.radii[2]}px` : 0,
        borderBottomRightRadius: showBottomBorder ? `${theme.radii[2]}px` : 0,
        boxShadow: `0 1px 2px ${theme.colors.neutralWeakest}`,
        ':not(:last-child)': {
          marginBottom: showBottomBorder && isCompactMode ? 4 : 0,
        },
      })}
    >
      {children}
    </Box>
  );
};
