import type { CurrencyCode, CurrencySymbol, PricingFormat } from './types';
import { SymbolPosition } from './types';
import { LOCALIZED_PRICES, DEFAULT_PRICE } from './pricingData';

export const getLocalPricingByCurrencyCode = (code: CurrencyCode) => {
  return Object.values({ ...LOCALIZED_PRICES, DEFAULT_PRICE }).find(
    (price) => price.currency.code === code,
  );
};

export const getFormattedPrice = (
  price: number | null | undefined,
  currencySymbol: CurrencySymbol,
  pricingFormat: PricingFormat | undefined,
) => {
  if (price === undefined || price === null || !pricingFormat) return '';

  const {
    decimalSeparator,
    thousandSeparator,
    symbolPosition,
    symbolSeparator,
  } = pricingFormat;
  const [integer, fractional] = price.toFixed(2).toString().split('.') as [
    string,
    string?,
  ];
  const formattedInteger = integer.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator,
  );
  const formattedDecimal = [formattedInteger, fractional].join(
    decimalSeparator,
  );

  switch (symbolPosition) {
    case SymbolPosition.TRAILING:
      return [formattedDecimal, symbolSeparator, currencySymbol].join('');
    case SymbolPosition.LEADING:
    default:
      return [currencySymbol, symbolSeparator, formattedDecimal].join('');
  }
};
