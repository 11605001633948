import type { FontWeightsEnum, KosmosFontSize } from '@mentimeter/ragnar-dsc';
import type { CSSProperties } from 'react';
import * as React from 'react';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import type { PlacementT } from '../Placement';

export interface TextEllipsisT {
  id: string;
  text: string;
  tooltipDescription: string;
  color?: string;
  style?: CSSProperties;
  fontWeight?: FontWeightsEnum;
  fontSize?: KosmosFontSize;
  placement?: PlacementT;
}
/**
 * This should only be used in places where a text can be to long and needs to be ellipsed. If ellipsis is used, a tooltip will also be shown.
 * To make ellipsis work the parent component of TextEllipsis needs to have a width property.
 */
export const TextEllipsis = (props: TextEllipsisT) => {
  const {
    text,
    tooltipDescription,
    id,
    style,
    color,
    fontWeight,
    fontSize,
    placement = 'bottom',
  } = props;
  const [isEllipsisActive, setIsEllipsisActive] = React.useState(false);
  const spanRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    const span = spanRef.current;
    if (span) {
      setIsEllipsisActive(span.offsetWidth < span.scrollWidth);
    }
  }, [text]);

  return (
    <>
      <Text
        ref={spanRef}
        id={id}
        truncate
        color={color}
        fontWeight={fontWeight}
        style={{ ...style }}
        fontSize={fontSize}
      >
        {text}
      </Text>

      {isEllipsisActive && (
        <Tooltip
          description={tooltipDescription}
          referenceId={id}
          placement={placement}
          delay={0}
        />
      )}
    </>
  );
};
