'use client';

import { core, type UserResponseT } from '@mentimeter/http-clients';
import { useEffect, useMemo, useState } from 'react';
import useSWR, { mutate, type SWRConfiguration } from 'swr';
import { trackUser } from '@api/tracking/client';
import { usersCacheKey } from './cache-keys';
import { userCache } from './internal-user-cache-wrapper';
import { getTrackingFields } from './tracking';
import { serverToState, stateToServer } from './transform';
import type { UserUpdateT, UseUserT } from './types';
import { useServerUser } from './ServerUserProvider';

export enum TokenValidityT {
  loading = 'LOADING',
  invalid = 'INVALID',
  valid = 'VALID',
  error = 'ERROR',
}

const isServer = typeof window === 'undefined';

export function useUser(
  configuration: SWRConfiguration<UserResponseT | undefined> = {},
) {
  const isAuthenticated = Boolean(!isServer && userCache.getToken());
  const [localStorageCacheUser] = useState(() =>
    isServer ? undefined : userCache.getUserCache(),
  );
  const serverUserContext = useServerUser();

  const fallbackConfig: SWRConfiguration = {};
  if (serverUserContext) {
    const { initialServerUser } = serverUserContext;
    if (!isServer) {
      userCache.setUserCache(initialServerUser);
    }
    fallbackConfig.fallbackData = initialServerUser ?? undefined;
  } else {
    fallbackConfig.fallbackData = localStorageCacheUser;
  }

  const { data, error, isLoading } = useSWR(
    usersCacheKey,
    async () => {
      /**
       * Read from the cache directly when doing the fetch
       * so that we are able to do mutations with swr.
       */
      const isAuthenticated = Boolean(!isServer && userCache.getToken());

      if (!isAuthenticated) {
        return undefined;
      }

      const { data } = await core({
        region: userCache.region,
      }).users.fetchUser();

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
      ...fallbackConfig,
      onSuccess: (res) => {
        if (res) {
          userCache.setUserCache(res);
        }
      },
      ...configuration,
    },
  );

  return useMemo(() => {
    if (isServer) {
      return {
        error: undefined,
        isAuthenticated: !!serverUserContext?.initialServerUser.session_token,
        isLoading,
        staleCache: false,
        user: serverUserContext?.initialServerUser,
        update: undefined,
      };
    }
    if (!isAuthenticated) {
      return {
        error: undefined,
        isAuthenticated: false,
        isLoading,
        staleCache: false,
        user: null,
        update: undefined,
      };
    }

    async function update(
      newData: UserUpdateT,
      context: string,
      options = { shouldUpdateOptimistically: true },
    ) {
      const { currentPassword, password, ...toLocalState } = newData;

      const updated = stateToServer(toLocalState);

      const serverResponse = core({ region: userCache.region })
        .put<any, { data: UserResponseT }>('/users', stateToServer(newData), {
          // @ts-expect-error-auto TS(2345) FIXME: Argument of type '{ handleErrorGlobally: boolean; ... Remove this comment to see the full error message
          handleErrorGlobally: true,
        })
        .then(({ data }) => {
          userCache.setUserCache(data);
          trackUser({
            event: 'Updated profile',
            properties: {
              context,
              fields: getTrackingFields(newData),
            },
          });
          return data;
        });

      const config = options.shouldUpdateOptimistically
        ? {
            revalidate: false,
            optimisticData: {
              ...data,
              ...updated,
              flags: { ...(data?.flags ?? {}), ...updated.flags },
            },
            rollbackOnError: true,
          }
        : { revalidate: false };

      /* update from server */
      return mutate(usersCacheKey, serverResponse, config);
    }

    return {
      error,
      isAuthenticated,
      isLoading,
      logout: userCache.logout,
      staleCache: Boolean(data ? data.fromCache : true),
      update,
      user: data ? serverToState(data) : null,
    };
  }, [data, error, isAuthenticated, isLoading, serverUserContext]) as UseUserT;
}

export const mutateUser = () => mutate(usersCacheKey);

export const useTokenIsValid = () => {
  const { error, staleCache, isAuthenticated } = useUser();
  const invalidToken = error?.response?.status === 401;

  useEffect(() => {
    if (invalidToken) {
      userCache.removeToken();
    }
  });

  if (invalidToken || !isAuthenticated) {
    return TokenValidityT.invalid;
  }

  if (error && staleCache) {
    return TokenValidityT.error;
  }
  if (staleCache) {
    return TokenValidityT.loading;
  }
  return TokenValidityT.valid;
};
